// Noa Ignite Global Resets/Styling

html {
	text-size-adjust: 100%;
}

* {
	font-variant-numeric: inherit;
}

body {
	font-weight: var(--font-weight-regular);
	font-size: var(--font-size-14);
	font-family: var(--font-family);
	/* stylelint-disable-next-line property-no-unknown */
	font-smooth: auto;
	line-height: var(--line-height-150);
	-webkit-font-smoothing: antialiased;
	scroll-behavior: smooth;
	font-variant-numeric: lining-nums proportional-nums;
}

html,
body {
	min-height: 100vh;
	margin: 0;
	padding: 0;
}

*,
::before,
::after {
	box-sizing: border-box;
	border-color: currentcolor;
	border-style: solid;
	border-width: 0;
}
