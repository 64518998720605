@mixin scrollbar($color: var(--color-interaction-2)) {
	&::-webkit-scrollbar {
		width: var(--size-3);
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color;
		background-clip: content-box;
		border: var(--size-05) solid transparent;
		border-radius: 100px;
	}
}
