@use 'libs/theme' as *;
@use '../checkbox/checkbox.module' as *;

.label {
	@include checkbox;

	--form-mark-size: 24px;
	--form-mark-scale: 0.666;
	--form-width-decrease: 4px; // The decrease is necessary in order to get the right size when we're scaling down the mark

	&:has(input:disabled) {
		opacity: 0.25;
	}

	&:has(input:checked) {
		.mark {
			.icon {
				background: var(--color-neutral-white);
				transform: translateX(calc(100% - var(--form-width-decrease))) scale(var(--form-mark-scale));

				.checkmark {
					transform: scale(1);
					opacity: 1;
				}
			}
		}
	}

	.mark {
		position: relative;
		width: calc((var(--form-mark-size) * 2) - var(--form-width-decrease));
		min-width: calc((var(--form-mark-size) * 2) - var(--form-width-decrease));
		height: var(--form-mark-size);
		border-radius: var(--border-radius-round);

		.icon {
			position: absolute;
			top: -1px;
			left: -1px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: var(--form-mark-size);
			height: var(--form-mark-size);
			min-height: auto;
			background: var(--color-dark-blue-white50);
			border-radius: 100%;
			transform: scale(var(--form-mark-scale));
			opacity: 1;
			aspect-ratio: 1/1;

			.checkmark {
				width: calc(var(--form-mark-size) / 2);
				height: calc(var(--form-mark-size) / 2);
				color: var(--color-dark-blue-base) !important;
				transform: scale(0.5);
				opacity: 0;
			}
		}
	}
}
