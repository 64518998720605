@use '../inputtext/inputtext.module' as *;

.label {
	&.forceWidth {
		.textarea {
			resize: vertical;
		}
	}
}

.textarea {
	@include input;

	height: auto;
	min-height: 52px;
	max-height: 60vh;
}
