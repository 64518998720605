@keyframes scrolled-to {
	0%,
	100% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}
}

.base {
	animation: scrolled-to 0.5s ease-in-out 1;
	animation-play-state: running;
}

.scrolledTo {
	animation: scrolled-to 0.8s ease-in-out infinite;
}
