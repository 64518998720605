/**
 * This file is automatically generated. Do not edit it directly.
 * Read docs/generating-variables.md for more information on how to add a new variable.
 */

:root {
	--size-breadcrumb: var(--size-12);
	--size-header-md: var(--size-18);
	--size-header: var(--size-16);
	--size-scale: 4px;
	--z-index-overlay: 40;
	--z-index-drawer: 30;
	--z-index-header: 20;
	--z-index-content-highlighted: 10;
	--z-index-content: 0; /* Default z-index for all elements */
	--z-index-background: -1;
	--color-content-drawer-overlay: rgba(0, 0, 0, 25%);
	--color-scroll-shadow-gradient-right: linear-gradient(90deg, rgba(0, 32, 57, 0) 0%, rgba(0, 32, 57, 100%) 50%);
	--color-scroll-shadow-gradient-left: linear-gradient(270deg, rgba(0, 32, 57, 0) 0%, rgba(0, 32, 57, 100%) 50%);
	--color-linear-gradient3: linear-gradient(0deg, rgba(0, 170, 206, 0.25), rgba(0, 170, 206, 0.25)),
		rgba(0, 32, 57, 0.25);
	--color-linear-gradient2: linear-gradient(180deg, rgba(0, 0, 0, 0%) 0%, rgba(0, 0, 0, 25%) 100%);
	--color-linear-gradient1: linear-gradient(0deg, rgba(0, 170, 206, 0.25), rgba(0, 170, 206, 0.25)),
		rgba(0, 32, 57, 0.75);
	--color-illustration-grey: #bcc1c5;
	--color-illustration-red: #d4b6b6;
	--color-illustration-orange: #e6b9a0;
	--color-illustration-green: #c4dac5;
	--color-illustration-blue: #8ac7d4;
	--color-illustration-sand: #b4a793;
	--color-signal-green70: #e1ede2;
	--color-signal-green: #3a853d;
	--color-signal-yellow70: #fcf0b3;
	--color-signal-yellow: #f5ce00;
	--color-signal-red70: #f5dfdf;
	--color-signal-red: #bf2626;
	--color-sand-white90: #f8f6f4;
	--color-sand-white75: #ece9e4;
	--color-sand-base: #b4a793;
	--color-light-blue-white75: #f3fafb;
	--color-light-blue-white50: #e6f4f7;
	--color-light-blue-base: #cee9ef;
	--color-middle-blue-dark-blue20: #008eb0;
	--color-middle-blue-base: #00aace;
	--color-dark-blue-white97: #f7f8f9;
	--color-dark-blue-white95: #f2f4f5;
	--color-dark-blue-white90: #e5e9eb;
	--color-dark-blue-white75: #bfc7cd;
	--color-dark-blue-white50: #808f9c;
	--color-dark-blue-white25: #40586b;
	--color-dark-blue-middle-blue20: #003c57;
	--color-dark-blue-middle-blue10: #002e48;
	--color-dark-blue-base: #002039;
	--color-dark-blue-75: #002039bf;
	--color-dark-blue-10: #0020391a;
	--color-accent-yellow-base: #f5ce00;
	--color-neutral-opacity20: rgba(255, 255, 255, 0.2);
	--color-neutral-opacity10: rgba(255, 255, 255, 0.1);
	--color-neutral-white: #fff;
	--color-neutral-black-25: #00000040;
	--color-neutral-black: #000;
	--color-neutral-09: #5e5d56;
	--color-neutral-08: #75746c;
	--color-neutral-07: #8e8d87;
	--color-neutral-06: #a7a7a2;
	--color-neutral-05: #c1c0bc;
	--color-neutral-04: #cdcdca;
	--color-neutral-03: #dadad7;
	--color-neutral-02: #e6e6e5;
	--color-neutral-01: #f3f3f2;
	--color-neutral-18: #060606;
	--color-neutral-17: #0d0d0d;
	--color-neutral-16: #131313;
	--color-neutral-15: #191919;
	--color-neutral-14: #202020;
	--color-neutral-13: #262626;
	--color-neutral-12: #2d2d2d;
	--color-neutral-11: #333;
	--color-neutral-10: #3a3a3a;
	--color-loading-hightlight: rgba(255, 255, 255, 0.25);
	--color-loading-state: rgba(0, 32, 57, 0.03);
	--color-state-error: #7b0b0b;
	--color-state-disabled: #464646;
	--box-shadow-large: 0px 7px 50px 0px rgba(0, 0, 0, 0.2), 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
	--box-shadow-medium: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
	--box-shadow-small: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
	--border-width-medium: 6px;
	--border-width-small: 3px;
	--border-width-xsmall: 1px;
	--border-width-2: 2px;
	--filter-active: brightness(0.9) saturate(0.75);
	--filter-focus: brightness(1.2) saturate(0.75);
	--transition-timing-function-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
	--transition-timing-function-ease-in-out: cubic-bezier(0.42, 0, 0.57, 1);
	--transition-duration-long: 1.2s;
	--transition-duration-smooth: 0.6s;
	--transition-duration-base: 0.3s;
	--transition-duration-quick: 0.12s;
	--transition-duration-instant: 0.075s;
	--backdrop-filter-large: blur(45px);
	--backdrop-filter-medium: blur(10px);
	--backdrop-filter-small: blur(5px);
	--border-radius-round: 10000px;
	--border-radius-xlarge: 16px;
	--border-radius-large: 8px;
	--border-radius-medium: 4px;
	--border-radius-small: 2px;
	--border-radius-xsmall: 1px;
	--letter-spacing-decreased: -0.03em;
	--letter-spacing-increased: 0.15em;
	--letter-spacing-default: 0.02em;
	--line-height-170: 1.7;
	--line-height-150: 1.5;
	--line-height-140: 1.4;
	--font-family: var(--font-raleway);
	--font-weight-extra-bold: 800;
	--font-weight-semi-bold: 600;
	--font-weight-regular: 400;
	--font-size-72: clamp(44px, 5vw, 72px);
	--font-size-56: 56px;
	--font-size-40: 40px;
	--font-size-34: 34px;
	--font-size-32: clamp(24px, 3vw, 32px);
	--font-size-24: 24px;
	--font-size-20: 20px;
	--font-size-18: 18px;
	--font-size-16: 16px;
	--font-size-14: 14px;
	--font-size-12: 12px;
	--font-size-10: 10px;
	--size-05: calc(var(--size-scale) * 0.5);
	--size-35: calc(var(--size-scale) * 35);
	--size-32: calc(var(--size-scale) * 32);
	--size-30: calc(var(--size-scale) * 30);
	--size-29: calc(var(--size-scale) * 29);
	--size-28: calc(var(--size-scale) * 28);
	--size-27: calc(var(--size-scale) * 27);
	--size-26: calc(var(--size-scale) * 26);
	--size-25: calc(var(--size-scale) * 25);
	--size-24: calc(var(--size-scale) * 24);
	--size-23: calc(var(--size-scale) * 23);
	--size-22: calc(var(--size-scale) * 22);
	--size-21: calc(var(--size-scale) * 21);
	--size-20: calc(var(--size-scale) * 20);
	--size-19: calc(var(--size-scale) * 19);
	--size-18: calc(var(--size-scale) * 18);
	--size-17: calc(var(--size-scale) * 17);
	--size-16: calc(var(--size-scale) * 16);
	--size-15: calc(var(--size-scale) * 15);
	--size-14: calc(var(--size-scale) * 14);
	--size-13: calc(var(--size-scale) * 13);
	--size-12: calc(var(--size-scale) * 12);
	--size-11: calc(var(--size-scale) * 11);
	--size-10: calc(var(--size-scale) * 10);
	--size-9: calc(var(--size-scale) * 9);
	--size-8: calc(var(--size-scale) * 8);
	--size-7: calc(var(--size-scale) * 7);
	--size-6: calc(var(--size-scale) * 6);
	--size-5: calc(var(--size-scale) * 5);
	--size-4: calc(var(--size-scale) * 4);
	--size-3: calc(var(--size-scale) * 3);
	--size-2: calc(var(--size-scale) * 2);
	--size-1: calc(var(--size-scale) * 1);
	--palette-lightgrey-tertiary: var(--color-middle-blue-base);
	--palette-lightgrey-secondary: var(--color-dark-blue-base);
	--palette-lightgrey-primary: var(--color-dark-blue-white95);
	--palette-lightsand-tertiary: var(--color-middle-blue-base);
	--palette-lightsand-secondary: var(--color-dark-blue-base);
	--palette-lightsand-primary: var(--color-sand-white90);
	--palette-sand-tertiary: var(--color-middle-blue-base);
	--palette-sand-secondary: var(--color-dark-blue-base);
	--palette-sand-primary: var(--color-sand-white75);
	--palette-lightblue-tertiary: var(--color-middle-blue-base);
	--palette-lightblue-secondary: var(--color-dark-blue-base);
	--palette-lightblue-primary: var(--color-light-blue-white50);
	--palette-white-tertiary: var(--color-middle-blue-base);
	--palette-white-secondary: var(--color-dark-blue-base);
	--palette-white-primary: var(--color-neutral-white);
	--palette-middleblue-tertiary: var(--color-dark-blue-base);
	--palette-middleblue-secondary: var(--color-neutral-white);
	--palette-middleblue-primary: var(--color-middle-blue-base);
	--palette-darkblue-tertiary: var(--color-middle-blue-base);
	--palette-darkblue-secondary: var(--color-neutral-white);
	--palette-darkblue-primary: var(--color-dark-blue-base);
	--palette-tertiary: var(--color-middle-blue-base);
	--palette-secondary: var(--color-dark-blue-base);
	--palette-primary: var(--color-neutral-white);
	--color-on-secondary: var(--color-neutral-white);
	--color-secondary: var(--color-middle-blue-base);
	--color-on-primary: var(--color-neutral-white);
	--color-primary: var(--color-dark-blue-base);
	--transition-long: var(--transition-duration-long) var(--transition-timing-function-ease-in-out);
	--transition-smooth: var(--transition-duration-smooth) var(--transition-timing-function-ease-in-out);
	--transition-base: var(--transition-duration-base) var(--transition-timing-function-ease-in-out);
	--transition-quick: var(--transition-duration-quick) var(--transition-timing-function-ease-in-out);
	--transition-instant: var(--transition-duration-instant) var(--transition-timing-function-ease-in-out);
	--size-fluid-huge: clamp(var(--size-12), 8vw, calc(4px * 20));
	--size-fluid-xlarge: clamp(var(--size-8), 6vw, calc(4px * 16));
	--size-fluid-large: clamp(var(--size-6), 5vw, calc(4px * 12));
	--size-fluid-medium: clamp(var(--size-4), 3.5vw, calc(4px * 8));
	--size-fluid-small: clamp(var(--size-3), 3vw, calc(4px * 6));
	--size-fluid-xsmall: clamp(var(--size-2), 2vw, calc(4px * 4));
	--size-fluid-tiny: clamp(var(--size-1), 1.25vw, calc(4px * 2));
}
