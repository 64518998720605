@use 'libs/theme' as *;
@use '../checkbox/checkbox.module' as *;

.group {
	@include column;

	gap: var(--size-3);

	&.horizontal {
		@include row;

		flex-wrap: wrap;
		gap: var(--size-5);
	}
}

.label {
	@include checkbox;

	.mark {
		border-radius: 50%;
	}
}

.dot {
	display: block;
	background-color: var(--color-neutral-white);
	border-radius: 100%;

	@include multiplier(10) {
		width: 10px;
		height: 10px;
	}

	.icon {
		width: 0;
		height: 0;
	}
}

.error {
	margin-top: var(--size-4);
}

.label_icon {
	margin-left: auto;
}
