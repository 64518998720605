@use '~/../libs/theme/src/lib/sass/global' as *;
@use 'libs/theme' as *;
@import 'react-loading-skeleton/dist/skeleton.css';

body {
	width: 100vw;
	overflow-x: hidden;
	color: var(--color-primary);
	font-size: var(--font-size-16);
	word-wrap: break-word;

	// CookieInformation bottom part.
	#coitable {
		display: none;
	}
}

// stylelint-disable-next-line selector-id-pattern
#__next {
	display: flex;
	flex-flow: column nowrap;
	min-height: 100vh;
}

pre {
	max-width: 100%;
	overflow-x: scroll;
}
