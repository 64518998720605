@use 'libs/theme' as *;
@use '../form' as *;

@mixin input-focus {
	padding-bottom: calc(var(--size-3) - 2px);
	border-color: var(--color-dark-blue-base);
	border-bottom-width: 2px;
}

// This is the styling for the Input
@mixin input {
	@extend %base;

	position: relative;
	width: 100%;
	max-width: 100%;
	min-height: 48px;
	padding: var(--size-3) var(--size-4) calc(var(--size-3) - var(--border-width-xsmall)); // Compensating from the 1px border
	border-bottom-width: var(--border-width-xsmall);
	border-radius: 0;
	outline: none;
	transition: padding-bottom var(--transition-quick), border var(--transition-quick), background var(--transition-quick),
		color var(--transition-quick), box-shadow var(--transition-quick), opacity var(--transition-quick);
	font-variant-numeric: lining-nums proportional-nums;

	&:focus-visible {
		@include input-focus;
	}

	// Hide arrow controllers for input numbers

	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		margin: 0;
		appearance: none;
	}

	/* Firefox */
	&[type='number'] {
		appearance: textfield;
	}

	&:hover,
	&:focus-visible,
	&:active {
		transition: padding-bottom border-color var(--transition-instant), border var(--transition-instant),
			background var(--transition-instant), color var(--transition-instant), box-shadow var(--transition-instant),
			opacity var(--transition-instant);
	}

	&[disabled] {
		@extend %disabled;
	}

	&:not([disabled]) {
		@include hover {
			border-color: var(--color-dark-blue-base);
		}

		@include active {
			border-color: var(--color-middle-blue-base);
		}
	}
}

.input {
	@include input;
}

.input[type='date'] {
	@include multiplier {
		appearance: none;
	}

	&::-webkit-calendar-picker-indicator {
		display: none;
	}

	&::-webkit-date-and-time-value {
		text-align: left;
	}
}

.label {
	&.iconPosition {
		&-before {
			&.hasIcon {
				.input {
					padding-left: var(--size-12);
				}
			}

			.icon {
				left: var(--size-4);
			}
		}

		&-after {
			&.hasIcon {
				.input {
					padding-right: var(--size-12);
				}
			}

			.icon {
				right: var(--size-4);
			}
		}
	}
}

.div {
	position: relative;
	width: 100%;
}

.iconWrapper {
	@include center;

	position: absolute;
	top: 0;
	right: 0;
	width: auto;
	pointer-events: none;

	&__clickable {
		cursor: pointer;
		pointer-events: all;
	}
}

.icon {
	transition: color var(--transition-base);
}
