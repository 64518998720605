// This file contains shared styling for Form Elements

@use 'libs/theme' as *;

%base {
	color: var(--color-primary);
	font-size: var(--font-size-16);
	font-family: var(--font-family);
	background-color: var(--color-dark-blue-white97);
	border-color: var(--color-dark-blue-white50);
	border-style: solid;

	@extend %transition;

	@include placeholder {
		color: var(--color-dark-blue-white50);
	}
}

%disabled {
	cursor: not-allowed;
	opacity: 0.5;
	filter: grayscale(0.5);
}

%transition {
	transition: border-color var(--transition-quick), background var(--transition-quick), color var(--transition-quick),
		box-shadow var(--transition-quick), opacity var(--transition-quick);

	&:hover,
	&:focus-visible,
	&:active {
		transition: border-color var(--transition-instant), background var(--transition-instant),
			color var(--transition-instant), box-shadow var(--transition-instant), opacity var(--transition-instant);
	}
}
