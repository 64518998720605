@use 'libs/theme' as *;

.fieldset {
	--fieldset-gap: var(--size-fluid-medium);
	--fieldset-field-size: auto;

	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: var(--fieldset-gap);

	@media (min-width: 500px) {
		grid-template-columns: repeat(auto-fill, minmax(var(--fieldset-field-size), 1fr));
	}
}

.row {
	@include column;

	grid-column: 1/-1;
	gap: var(--fieldset-gap);
	align-items: flex-start;
	justify-content: flex-start;

	@media (min-width: 500px) {
		@include row;
	}

	&.fullWidth {
		width: 100%;

		// > * {
		// 	flex: 1;
		// 	grid-column: 1/-1;
		// }
	}
}

.center {
	display: flex;
	grid-column: 1/-1;
	align-items: center;
	justify-content: center;
	column-gap: 10px;

	&.direction {
		&-row {
			flex-flow: row nowrap;
		}

		&-column {
			flex-flow: column nowrap;
		}
	}

	&.gap {
		&-small {
			column-gap: var(--size-4);
			row-gap: var(--size-4);
		}
	}
}
