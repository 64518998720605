@use 'libs/theme' as *;

// Base styling
.button {
	--button-background: inherit;
	--button-color: inherit;
	--button-icon-color: inherit;

	/* Keep 0px to use with calc() */
	--border-width: 0px;

	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	color: var(--button-color);
	background: var(--button-background);
	border: none;
	outline: none;
	cursor: pointer;
	transition-timing-function: var(--transition-timing-function-ease-in-out);
	transition-duration: var(--transition-duration-base);
	transition-property: border background color border box-shadow filter opacity;
	appearance: none;

	@include disabled {
		--button-background: var(--color-dark-blue-white90);
		--button-color: var(--color-dark-blue-white75);
		--button-icon-color: var(--color-dark-blue-white75);

		border-color: var(--color-dark-blue-white90);
		cursor: not-allowed;
	}

	@include hover {
		transition-duration: var(--transition-duration-quick);
	}

	@include active {
		transition-duration: var(--transition-duration-instant);
	}

	@include is-a-tag {
		text-decoration: none;

		@include multiplier {
			margin-inline-start: 0;
			margin-inline-end: 0;
		}
	}

	svg {
		flex-shrink: 0;
		color: var(--button-icon-color);
	}
}

// Handle missing :is in unsupported browsers
a.button {
	text-decoration: none;
	margin-inline-start: 0;
	margin-inline-end: 0;
}

// Variants
.variant {
	font-weight: var(--font-weight-semi-bold);

	&-fill,
	&-inline-thick,
	&-stroke {
		font-weight: var(--font-weight-semi-bold);
		font-family: var(--font-family);
		border-radius: var(--border-radius-medium);
	}

	&-fill {
		--border-width: 2px;

		background-color: var(--button-background);
		border: var(--border-width) solid var(--button-background);
		outline: none;
		cursor: pointer;

		&-primary {
			--button-background: var(--color-primary);
			--button-color: var(--color-on-primary);
			--button-icon-color: var(--color-secondary);

			@include hover {
				--button-background: var(--color-dark-blue-middle-blue20);
			}
		}

		&-secondary {
			--button-background: var(--color-secondary);
			--button-color: var(--color-on-secondary);
			--button-icon-color: var(--color-primary);

			@include hover {
				--button-background: var(--color-middle-blue-dark-blue20);
			}
		}
	}

	// Stroke
	&-stroke {
		--button-color: var(--color-primary);
		--button-background: transparent;
		--button-icon-color: var(--color-secondary);
		--border-width: 2px;

		border: var(--border-width) solid var(--button-color);
		box-shadow: 0 0 0 0 var(--button-color);

		@include hover {
			--button-background: var(--color-dark-blue-white95);
		}

		@include active {
			box-shadow: 0 0 0 2px var(--button-color);
		}
	}

	// Plain variants
	&-plain {
		--button-color: var(--button-color-text);
		--button-background-color: transparent;
		--button-icon-color: var(--color-primary);

		font-weight: var(--font-weight-semi-bold);
		font-family: var(--font-family);
		background-color: var(--button-background-color);
		border-radius: var(--border-radius-medium);
		outline: none;
		cursor: pointer;
		transition: background var(--transition-base), color var(--transition-base), outline var(--transition-base),
			opacity var(--transition-base);

		&:hover,
		&:focus-visible,
		&:active {
			transition: background var(--transition-instant), color var(--transition-quick), outline var(--transition-quick),
				opacity var(--transition-base);
		}

		@include hover {
			--button-background-color: var(--color-interaction-05);
		}

		@include active {
			--button-background-color: var(--color-interaction-1);
		}
	}

	// Plain variants
	&-inline,
	&-inline-thick {
		--button-icon-color: var(--palette-tertiary);

		color: inherit;
		font-size: inherit;
		background: none;
		outline: none;
		cursor: pointer;
		margin-inline-end: 3px;
		margin-inline-start: 3px;

		@include multiplier {
			height: auto;
			padding: 0;

			--button-gap: var(--size-2);
		}
	}
}

.spaceBetween {
	justify-content: space-between;
}

.isLoading {
	cursor: wait;
	opacity: 0.6;
}

.iconPosition {
	&-before {
		flex-direction: row-reverse;
	}

	&-after {
		flex-direction: row;
	}
}

.unstyled {
	padding: 0;
	color: currentcolor;
	font-size: inherit;
	background: none;
	cursor: pointer;
}

// Sizes
.size {
	--button-font-size: var(--font-size-16);

	gap: var(--button-gap);
	padding: var(--button-padding-vertical) var(--button-padding-horizontal);
	font-size: var(--button-font-size);

	&-medium {
		--button-padding-vertical: calc(var(--size-3) - var(--border-width));
		--button-padding-horizontal: var(--size-5);
		--button-gap: var(--size-3);
	}

	&-large {
		--button-padding-vertical: calc(var(--size-4) - var(--border-width));
		--button-padding-horizontal: var(--size-6);
		--button-gap: var(--size-4);
	}
}

.iconOnly {
	width: auto;
	border-radius: 100%;
	aspect-ratio: 1/1;

	@include expand-click-area-before;

	&.size {
		height: auto;

		&-medium {
			padding: var(--size-3);
		}

		&-large {
			padding: var(--size-4);
		}
	}
}
