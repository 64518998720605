.portal {
	position: fixed;
	inset: 0;
	z-index: var(--z-index-overlay);
	pointer-events: none;

	> * {
		pointer-events: all;
	}

	&__scrollable {
		position: absolute;
	}
}
