@use 'libs/theme' as *;
@use '../form' as *;

// This is the styling for the Radio and Checkbox Marks
@mixin checkbox {
	--form-mark-size: 24px;
	--form-mark-icon-size: 13px;
	--form-mark-color: var(--color-on-level-3);
	--form-mark-background: transparent;

	&.small {
		--form-mark-size: 20px;
		--form-mark-icon-size: 10px;
	}

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	color: var(--color-on-level-1);
	font-size: var(--font-size-16);
	cursor: pointer;

	@extend %transition;

	&:has(input[disabled]) {
		@extend %disabled;
	}

	&:not(:has(input[disabled])) {
		@include hover {
			.mark {
				border-color: var(--color-dark-blue-base);
			}
		}
	}

	input:not([disabled]) + & {
		@include hover {
			border-color: var(--color-dark-blue-base);
		}
	}

	&:has(input:checked) {
		.mark {
			background-color: var(--color-dark-blue-base);
			border-color: var(--color-dark-blue-base);

			.icon {
				transform: scale(1) translate(0);
				opacity: 1;
			}
		}
	}

	&:focus-within {
		.mark {
			border-color: var(--color-dark-blue-base);
		}
	}

	.wrapper {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;

		&--error {
			margin-bottom: var(--size-2);
		}

		column-gap: var(--size-2);
		transition: border var(--transition-quick);
	}

	.input {
		@include visually-hidden;
	}

	[data-optional-label] {
		margin-left: var(--size-2);
		font-size: var(--font-size-12);
		opacity: 0.5;
	}

	.mark {
		@extend %base;

		display: flex;
		align-items: center;
		justify-content: center;
		width: var(--form-mark-size);
		height: var(--form-mark-size);
		color: var(--color-primary);
		background-color: var(--color-neutral-white);
		border-width: 1px;
		border-radius: var(--border-radius-medium);
		outline: none;
		transition: border-color var(--transition-quick), background var(--transition-quick), color var(--transition-quick),
			box-shadow var(--transition-quick), opacity var(--transition-quick);
		aspect-ratio: 1/1;

		&:hover,
		&:focus-visible,
		&:active {
			transition: border-color var(--transition-instant), background var(--transition-instant),
				color var(--transition-instant), box-shadow var(--transition-instant), opacity var(--transition-instant);
		}

		@include placeholder {
			color: var(--color-dark-blue-white50);
		}

		.icon {
			width: var(--form-mark-icon-size);
			height: var(--form-mark-icon-size);
			color: var(--color-neutral-white);
			transform: scale(0.5) translateY(2px);
			opacity: 0;
			transition-timing-function: var(--transition-timing-function-ease-in-out);
			transition-duration: var(--transition-duration-quick);
			transition-property: opacity, transform, width, height, border, background;
			pointer-events: none;
		}
	}
}

.label {
	@include checkbox;
}
