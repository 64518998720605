:root {
	--responsive-spacing-20: 20px;
	--responsive-spacing-24: 24px;
	--responsive-spacing-36: 36px;
	--responsive-spacing-48: 48px;
	--responsive-spacing-64: 64px;
	--responsive-spacing-72: 72px;
	--responsive-spacing-96: 96px;
	--responsive-spacing-144: 144px;

	@media (min-width: $breakpoint-lg) {
		--responsive-spacing-20: 24px;
		--responsive-spacing-24: 32px;
		--responsive-spacing-36: 48px;
		--responsive-spacing-48: 64px;
		--responsive-spacing-64: 72px;
		--responsive-spacing-72: 96px;
		--responsive-spacing-96: 128px;
		--responsive-spacing-144: 192px;
	}
}
