@use 'libs/theme' as *;

$bp-m: 500px;

:export {
	bp-m: $bp-m;
}

.dialog {
	@include scrollbar;

	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	width: var(--dialog-width);
	max-height: calc(100% - 10vh);
	margin: 10vh auto 0;
	padding: var(--dialog-padding);
	overflow-x: hidden;
	overflow-y: auto;
	color: var(--palette-secondary);
	background-color: var(--palette-primary);
	border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
	box-shadow: var(--box-shadow-large);

	@media (min-width: $bp-m) {
		top: 0;
		bottom: auto;
		height: auto;
		max-height: 80vh;
		margin: 10vh auto;
		border-radius: var(--border-radius-large);
	}

	&__scrollable {
		position: absolute;
		max-height: none;
	}

	&.size {
		&-small {
			--dialog-width: clamp(200px, 100vw, 600px);
			--dialog-padding: var(--size-fluid-medium);
		}

		&-large {
			--dialog-width: clamp(300px, 100vw, 800px);
			--dialog-padding: var(--size-fluid-xlarge);
		}

		&-xlarge {
			--dialog-width: var(--grid-columns-10);
			--dialog-padding: var(--size-fluid-huge);
		}

		@media (min-width: $bp-m) {
			&-small {
				--dialog-width: clamp(300px, 60vw, 450px);
			}

			&-large {
				--dialog-width: clamp(300px, 60vw, 800px);
			}
		}
	}

	.close {
		position: absolute;
		top: var(--size-3);
		right: var(--size-3);
	}
}

.actions {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	padding-top: var(--size-4);
	column-gap: var(--size-fluid-tiny);
}
