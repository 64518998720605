/// If you want to visually hide an element, while remain their focus states.
/// @group Accessibility
/// @example scss - SCSS Input
/// .selector {
///   @include visually-hidden;
/// }
///
@mixin visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	word-wrap: normal;
	clip: rect(1px, 1px, 1px, 1px);
}

// @mixin expand-click-area-before
// @group Accessibility
// @description
// Expand the click area of an element.
// @example scss - SCSS Input
//   .selector {
//     position: relative;
//     &::before {
//       @include expand-click-area-before;
//     }
@mixin expand-click-area($size: 1) {
	--icon-hover-size: #{$size};

	position: absolute;
	z-index: var(--z-index-background);
	border-radius: 50%;
	content: '';
	inset: calc(var(--icon-hover-size) * -0.7rem);

	// increase tap target size for touch devices
	@media (pointer: coarse) {
		inset: calc(var(--icon-hover-size) * -1rem);
	}
}

@mixin expand-click-area-before($size: 1) {
	position: relative;

	&:focus {
		outline-color: transparent;
	}

	&::before {
		@include expand-click-area($size);
	}
}

@mixin expand-click-area-after($size: 1) {
	position: relative;

	&:focus {
		outline-color: transparent;
	}

	&::after {
		@include expand-click-area($size);
	}
}
