.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000, 0.15);
	appearance: none;

	&__alt {
		background: var(--color-linear-gradient3);
	}
}
