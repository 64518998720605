@use 'libs/theme' as *;
@use '../form' as *;

.label {
	@include center;

	display: inline-flex;
	flex-direction: row;
	max-width: 100%;
	min-height: auto;
	margin: 0 0 auto;
	color: var(--color-primary);
	font-size: var(--font-size-16);
	font-family: var(--font-family);

	&.margin {
		@include multiplier(10) {
			> span {
				margin-bottom: var(--size-3);
			}
		}
	}

	&[aria-disabled='true'] {
		[data-icon] {
			color: var(--color-dark-blue-white50);
		}
	}

	@media (max-width: 500px) {
		width: 100%;
	}

	[data-label-span] {
		--label-border-width: 1px;

		@include row;

		display: inline-flex;
		flex: 0 1;
		color: inherit;
		font-weight: var(--font-weight-semi-bold);
		transition: color var(--transition-base);
	}

	/// Context
	&[data-label-position='over'],
	&[data-label-position='under'] {
		@include column;

		align-items: flex-start;
		justify-content: flex-start;
		text-align: left;
	}

	&[data-label-position='under'] {
		[data-label-span] {
			margin-top: var(--size-2);
		}
	}

	&[data-label-position='over'] {
		[data-label-span] {
			margin-bottom: var(--size-1);
		}
	}

	&[data-label-position='after'] {
		text-align: left;

		[data-label-span] {
			margin-left: var(--size-2);
		}
	}

	&[data-label-position='before'] {
		text-align: right;

		[data-label-span] {
			margin-right: var(--size-2);
		}
	}
}

.description {
	margin-bottom: var(--size-3);
	color: var(--color-primary);
	font-weight: var(--font-weight-regular);
	font-size: var(--font-size-14);
	font-family: var(--font-family);
	text-transform: none;
}

.optional-label {
	margin-bottom: var-neg(--size-2);
	margin-left: auto;
	color: var(--color-primary);
	font-weight: var(--font-weight-regular);
	font-size: var(--font-size-14);
	font-family: var(--font-family);
}
