// stylelint-disable scss/function-no-unknown
@function icon($name, $overrides: null) {
	@if $overrides {
		@return svg-load($name, $overrides);
	} @else {
		@return svg-load($name);
	}
}

@mixin background-svg($name, $position, $size: 16px, $overrides: null) {
	@if $overrides {
		background-image: icon($name, $overrides);
	} @else {
		background-image: icon($name);
	}

	background-repeat: no-repeat;
	background-position: $position;
	background-size: $size;
}
