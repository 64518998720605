:root {
	--grid-columns: 12;
	--grid-columns-12: calc((12 / var(--grid-columns)) * 100%);
	--grid-columns-11: calc((11 / var(--grid-columns)) * 100%);
	--grid-columns-10: calc((10 / var(--grid-columns)) * 100%);
	--grid-columns-9: calc((9 / var(--grid-columns)) * 100%);
	--grid-columns-8: calc((8 / var(--grid-columns)) * 100%);
	--grid-columns-7: calc((7 / var(--grid-columns)) * 100%);
	--grid-columns-6: calc((6 / var(--grid-columns)) * 100%);
	--grid-columns-5: calc((5 / var(--grid-columns)) * 100%);
	--grid-columns-4: calc((4 / var(--grid-columns)) * 100%);
	--grid-columns-3: calc((3 / var(--grid-columns)) * 100%);
	--grid-columns-2: calc((2 / var(--grid-columns)) * 100%);
	--grid-columns-1: calc((1 / var(--grid-columns)) * 100%);
}
