@use 'sass:list';

@mixin row {
	display: flex;
	flex-direction: row;
	width: 100%;
}

@mixin column {
	display: flex;
	flex-direction: column;
	width: 100%;
}

@mixin center {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 100%;
}

@mixin grid($rows: 12, $columns: 12) {
	--grid-columns: #{$columns};
	--grid-rows: #{$rows};

	display: grid;
	grid-template-rows: repeat(var(--grid-rows), 1fr);
	grid-template-columns: repeat(var(--grid-columns), 1fr);
}

@mixin span-row($start: 1, $end: 2) {
	--grid-row-start: #{$start};

	@if $end < $start {
		--grid-row-end: -1;
	} @else {
		--grid-row-end: #{$end};
	}

	grid-row: var(--grid-row-start) / var(--grid-row-end);
}

@mixin span-column($start: 1, $end: 2) {
	--grid-column-start: #{$start};

	@if $end < $start {
		--grid-column-end: -1;
	} @else {
		--grid-column-end: #{$end};
	}

	grid-column: var(--grid-column-start) / var(--grid-column-end);
}

@mixin span(
	$row: (
		1,
		2,
	),
	$column: (
		1,
		2,
	)
) {
	@include span-row(nth($row, 1), nth($row, 2));
	@include span-column(nth($column, 1), nth($column, 2));
}
