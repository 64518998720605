@use 'libs/theme' as *;

.container {
	width: 100%;
	max-width: $breakpoint-xl;
	margin: 0 auto;
	padding: 0 var(--size-6);

	@media (min-width: $breakpoint-sm) {
		padding: 0 var(--size-8);
	}

	@media (min-width: $breakpoint-md) {
		padding: 0 var(--size-10);
	}

	@media (min-width: $breakpoint-lg) {
		padding: 0 var(--size-12);
	}
}
