@use '../lib' as *;

// Global styles
// Import this file in your main stylesheets to use the global styles.
// It includes the reset and global variables

a,
p,
button {
	color: inherit;
	font-size: inherit;
	font-family: var(--font-family);
	line-height: var(--line-height-150);
}

button {
	line-height: var(--line-height-default);
}

a {
	display: inline-block;
}

strong,
b {
	font-weight: var(--font-weight-semi-bold);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	&:first-child {
		margin-top: 0;
	}
}

.h0 {
	@include h0;
}

h1,
.h1 {
	@include h1;
}

h2,
.h2 {
	@include h2;
}

h3,
.h3 {
	@include h3;
}

h4,
.h4 {
	@include h4;
}

h5,
.h5 {
	@include h5;
}

pre,
code {
	font-size: 90%;
	font-family: monospace;
	white-space: pre-wrap;
}
