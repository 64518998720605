@use 'libs/theme' as *;

.popup {
	@include multiplier {
		padding: var(--size-fluid-tiny) var(--size-fluid-tiny);
		color: var(--color-on-level-1);
		background: var(--color-neutral-01);
		border: var(--border-width-xsmall) solid var(--color-neutral-02);
		border-radius: var(--border-radius-medium);
		box-shadow: var(--box-shadow-small);
	}
}
