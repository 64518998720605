.skipContent {
	position: absolute;
	top: 0;
	left: 50%;
	z-index: var(--z-index-overlay);
	display: inline-block;
	padding: 5px 10px;
	overflow: hidden;
	color: var(--color-neutral-04);
	font-size: var(--font-size-10);
	text-decoration: none;
	background-color: var(--color-on-level-4);
	border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
	transform: translate(-50%, -100%);
	opacity: 0;
	transition: transform var(--transition-quick), opacity var(--transition-quick);
	pointer-events: none;

	&:focus {
		outline: none;
		transform: translate(-50%, 0);
		opacity: 1;
		pointer-events: auto;
	}
}
