.icon {
	--icon-padding: 0;

	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: var(--icon-padding);
	color: var(--icon-color);
	line-height: 1;
	text-align: center;
	vertical-align: middle;
	outline: none;
}
