@use 'libs/theme' as *;

.error {
	position: relative;
	z-index: var(--z-index-content);
	width: 100%;
	margin-top: -1px;
	padding: var(--size-1) var(--size-4);
	color: var(--color-dark-blue-base);
	font-size: var(--font-size-14);
	font-family: var(--font-family);
	background: var(--color-signal-red70);
	border-top: 1px solid var(--color-signal-red);
}
