@use 'libs/theme' as *;
@use '../form' as *;
@use '../inputtext/inputtext.module' as *;

.selectLabel {
	--select-mark-size: 20px;

	.container {
		@extend %transition;

		width: 100%;
	}

	&[aria-disabled='true'] {
		.container {
			@extend %disabled;
		}
	}

	.hasChosenMultiple {
		:global {
			.react-select {
				&__multi-value {
					&::before {
						visibility: hidden;
						content: '# options selected';
					}

					> * {
						display: none;
					}
				}
			}
		}
	}

	.isMultipleChoice {
		:global {
			.react-select {
				&__option {
					--form-mark-size: 20px;
					--select-mark-size: 12px;
					--form-mark-color: var(--color-on-level-3);

					display: flex;
					align-items: center;
					background: none;

					&::before {
						display: flex;
						align-items: center;
						justify-content: center;
						width: var(--form-mark-size);
						height: var(--form-mark-size);
						margin-right: var(--size-2);
						color: var(--color-primary);
						background-color: var(--color-neutral-white);
						background-position: center;
						border-width: 1px;
						border-radius: var(--border-radius-medium);
						outline: none;
						transition: border-color var(--transition-quick), background var(--transition-quick),
							color var(--transition-quick), box-shadow var(--transition-quick), opacity var(--transition-quick);
						content: ' ';
						aspect-ratio: 1/1;
					}

					&--is-selected {
						&::before {
							background-color: var(--color-dark-blue-base);
							border-color: var(--color-dark-blue-base);

							@include background-svg('checkmark-fat.svg', center, var(--select-mark-size), 'color=white');
						}
					}
				}
			}
		}
	}

	:global(.react-select__control) {
		@include input;

		cursor: pointer;
	}

	:global(.react-select__control--is-focused) {
		@include input-focus;
	}

	:global {
		.react-select {
			&__menu {
				margin-top: var(--size-1);
				background-color: var(--color-neutral-white);
				border: 1px solid var(--color-dark-blue-white90);
				border-radius: var(--border-radius-medium);
				box-shadow: var(--box-shadow-medium);
			}

			&__option {
				padding: var(--size-3);
				font-weight: var(--font-weight-regular);
				font-size: var(--font-size-16);
				cursor: pointer;
				transition: background-image var(--transition-quick);

				@include background-svg(
					'checkmark.svg',
					right var(--size-3) top 50%,
					var(--select-mark-size),
					'color=transparent'
				);

				@include hover {
					background-color: var(--color-dark-blue-white97);
				}

				&:not(:first-of-type) {
					border-top: 1px solid var(--color-dark-blue-white90);
				}

				&--is-selected {
					@include background-svg(
						'checkmark.svg',
						right var(--size-3) top 50%,
						var(--select-mark-size),
						'color=#002039'
					);
				}
			}

			&__value-container {
				gap: var(--size-1);
			}

			&__multi-value {
				&:not(:first-child) {
					display: none;
				}

				&__remove {
					display: none;
				}
			}

			&__placeholder {
				overflow: hidden;
				color: var(--color-dark-blue-white50);
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
}

.wrapper {
	position: relative;
	width: 100%;
}

.count {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	padding: var(--size-3) var(--size-4) calc(var(--size-3) - var(--border-width-xsmall)); // Compensating from the 1px border
}
